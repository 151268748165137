import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import get from 'lodash/get'
import find from 'lodash/find'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import some from 'lodash/some'
import replace from 'lodash/replace'

import Button from '@eig-builder/core-ui/Button'
import Typography from '@eig-builder/core-ui/Typography'

import PromotePlan from '@eig-builder/compositions-promote-plan'
import GatorPlanFeatures from 'modules/checkout/enums/gator-plan-features'
import ComparePlansModalFeature from './../compare-plans-modal-feature'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import CompositionsModal from '@eig-builder/compositions-modal'
import Text from '@eig-builder/module-localization'
import './lang'
import Paper from '@material-ui/core/Paper'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getCreativEmailPlanPromotion } from '../../constants'

const PromotePlanWrapper = styled(Paper)`
  height: 280px;
  padding: 0 5px;
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.palette.border.main};
  border-right: 1px solid ${(props) => props.theme.palette.border.main};
  ${(props) => (props.borderLeft ? 'border-left: 1px solid ' + props.theme.palette.border.main : '')};
  border-radius: 0 !important;
  box-shadow: none !important;

  ${({ first }) =>
    first &&
    css`
      padding: 0 5px 0 6px;
      margin-left: -1px;
    `}

  &.mobile {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-left: 1px solid ${(props) => props.theme.palette.border.main};
  }
`

const ExpansionPanelWrapper = styled(ExpansionPanel)`
  margin-bottom: 8px;
  border: 1px solid ${(props) => props.theme.palette.border.main};
`

const ExpansionPanelDetailsStyle = styled(ExpansionPanelDetails)`
  display: block !important;
  padding: 8px !important;
`

const NonIncludedFeature = styled.span`
  text-decoration: line-through;
  color: ${(props) => props.theme.palette.darkGray.light};
`

const PopularPlan = styled.div`
  text-align: center;
  background: ${(props) => props.theme.palette.primary.light};
  border-top: 1px solid ${(props) => props.theme.palette.border.main};
  border-left: 1px solid ${(props) => props.theme.palette.border.main};
  border-right: 1px solid ${(props) => props.theme.palette.border.main};
  ${(props) => (props.borderBottom ? 'border-bottom: 1px solid ' + props.theme.palette.border.main : '')};
  color: ${(props) => props.theme.palette.white.main};
`
const PopularPlanPlaceHolder = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.white.main};
  border-left: 1px solid ${(props) => props.theme.palette.white.main};
  border-right: 1px solid ${(props) => props.theme.palette.white.main};
`

class ComparePlansModalPlanComponent extends Component {
  static propTypes = {
    plan: PropTypes.object,
    allFeatures: PropTypes.array,
    borderLeft: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    vatPercentage: PropTypes.number,
    isMobile: PropTypes.bool
  }

  static getDerivedStateFromProps (props, state) {
    if (state && !state.currencySymbol && props.billingInfo) {
      return {
        currencySymbol: props.billingInfo.symbol
      }
    }

    return null
  }

  constructor (props) {
    super(props)
    this.state = {}
  }

  toComparePlansModalFeatures = (items) =>
    items.map((item, index) => (
      <ComparePlansModalFeature
        key={`compare-plans-modal-${index}`}
        borderLeft={this.props.borderLeft}
        isMobile={this.props.isMobile}
        className='justify-content-center'
        borderBottom={index === items.length - 1}
      >
        {item}
      </ComparePlansModalFeature>
    ))

  renderFeatures = () => {
    const plan9Available = ['websitebuilder', 'sitey', 'sitelio']
    const isPlan9Available = plan9Available.includes(getRuntimeConfig()._brand)
    const isWebsitebuilder = getRuntimeConfig()._brand === 'websitebuilder'
    const isCreativEmail = getRuntimeConfig()._brand === 'creativemail'
    const isCTCT = getRuntimeConfig()._brand === 'constantcontact'
    const isGator = getRuntimeConfig()._brand === 'gator'
    let features = map(this.props.allFeatures, (feature, index, arr) => {
      const hasFeature = some(this.props.plan.features, (ft) => ft === feature)
      return (
        <ComparePlansModalFeature
          borderLeft={this.props.borderLeft}
          isMobile={this.props.isMobile}
          className='justify-content-center'
          borderBottom={arr.length - 1 === index && !isWebsitebuilder && !isPlan9Available && !isCTCT && !isCreativEmail && !isGator}
        >
          {hasFeature ? feature === "Bookings" && isGator && this.props.plan.sku === "PLAN1" ? <NonIncludedFeature> {feature} </NonIncludedFeature> : feature : <NonIncludedFeature> {feature} </NonIncludedFeature>}
        </ComparePlansModalFeature>
      )
    })

    // HACK HACK HARD CODED HACK HACK
    if (isPlan9Available) {
      if (this.props.plan.sku === 'PLAN9') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom={!isWebsitebuilder}
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.basicWebsiteStatistics' />
          </ComparePlansModalFeature>
        )
      } else {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom={!isWebsitebuilder}
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.advanceWebsiteStatistics' />
          </ComparePlansModalFeature>
        )
      }
    }
    if (isWebsitebuilder) {
      if (this.props.plan.sku === 'PLAN9') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeProducts' values={{ value: 3 }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee' values={{ value: '3%' }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.basic' /> }}
            />
          </ComparePlansModalFeature>
        )
      } else if (this.props.plan.sku === 'PLAN2') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeProducts' values={{ value: 5 }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee' values={{ value: '3%' }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.basic' /> }}
            />
          </ComparePlansModalFeature>
        )
      } else if (this.props.plan.sku === 'PLAN3') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeProducts' values={{ value: 10 }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee' values={{ value: '3%' }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.basic' /> }}
            />
          </ComparePlansModalFeature>
        )
      } else if (this.props.plan.sku === 'PLAN4') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.storeProducts'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.unlimited' /> }}
            />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.no' /> }}
            />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.advanced1' /> }}
            />
          </ComparePlansModalFeature>
        )
      }
    } else if (isGator) {
      if (this.props.plan.sku === 'PLAN1') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeProducts' values={{ value: GatorPlanFeatures.PLAN1.STORE_PRODUCTS }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee' values={{ value: GatorPlanFeatures.PLAN1.STORE_TRANSACTION_FEE }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.basic' /> }}
            />
          </ComparePlansModalFeature>
        )
      } else if (this.props.plan.sku === 'PLAN3') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeProducts' values={{ value: GatorPlanFeatures.PLAN3.STORE_PRODUCTS }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee' values={{ value: GatorPlanFeatures.PLAN3.STORE_TRANSACTION_FEE }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.basic' /> }}
            />
          </ComparePlansModalFeature>
        )
      } else if (this.props.plan.sku === 'PLAN4') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.storeProducts'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.unlimited' /> }}
            />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.no' /> }}
            />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.advanced1' /> }}
            />
          </ComparePlansModalFeature>
        )
      }
    } else if (isCreativEmail) {
      switch (this.props.plan.sku) {
        case 'PLAN2':
          features = this.toComparePlansModalFeatures([
            <Text key='compare-plans-1' message='modules.upgradeModels.comparePlansModalPlan.unlimited' />,
            <Text key='compare-plans-2' message='modules.upgradeModels.comparePlansModalPlan.unlimited' />,
            <Text key='compare-plans-3' message='modules.upgradeModels.comparePlansModalPlan.unlimited' />,
            10,
            1000,
            <Text key='compare-plans-4' message='modules.upgradeModels.comparePlansModalPlan.websiteFormOnly' />,
            <Text key='compare-plans-5' message='modules.upgradeModels.comparePlansModalPlan.yes' />
          ])
          break
        case 'PLAN3':
        case 'PLAN1_CE':
          features = this.toComparePlansModalFeatures([
            <Text
              key='compare-plans-6'
              message='modules.upgradeModels.comparePlansModalPlan.totalContacts'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.unlimited' /> }}
            />,
            <Text
              key='compare-plans-7'
              message='modules.upgradeModels.comparePlansModalPlan.customAudiences'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.unlimited' /> }}
            />,
            <Text
              key='compare-plans-8'
              message='modules.upgradeModels.comparePlansModalPlan.premiumSections'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.unlimited' /> }}
            />,
            <Text key='compare-plans-9' message='modules.upgradeModels.comparePlansModalPlan.campaignsPerMonth' values={{ value: 10 }} />,
            <Text
              key='compare-plans-10'
              message='modules.upgradeModels.comparePlansModalPlan.uniqueEmailsPerMonth'
              values={{ value: 1000 }}
            />,
            <Text key='compare-plans-11' message='modules.upgradeModels.comparePlansModalPlan.csvUpload' />
            // footerads: <Text message='modules.upgradeModels.comparePlansModalPlan.none' />
          ])
          break
        case 'PLAN4':
          features = this.toComparePlansModalFeatures([
            <Text key='compare-plans-12' message='modules.upgradeModels.comparePlansModalPlan.unlimited' />,
            <Text key='compare-plans-13' message='modules.upgradeModels.comparePlansModalPlan.unlimited' />,
            <Text key='compare-plans-14' message='modules.upgradeModels.comparePlansModalPlan.unlimited' />,
            10,
            1000,
            <Text key='compare-plans-15' message='modules.upgradeModels.comparePlansModalPlan.yes' />,
            <Text key='compare-plans-16' message='modules.upgradeModels.comparePlansModalPlan.none' />
          ])
          break
        default:
          features = []
          break
      }
    } else if (isCTCT) {
      if (this.props.plan.sku === 'PLAN1') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeProducts' values={{ value: 10 }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee' values={{ value: '3%' }} />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.basic' /> }}
            />
          </ComparePlansModalFeature>
        )
      } else if (this.props.plan.sku === 'PLAN3') {
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.storeProducts'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.unlimited' /> }}
            />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
          >
            {/* <Text message='modules.upgradeModels.comparePlansModalPlan.none' /> */}
            {/* Temp change to 1.5%. Jira ticket AWSM-845 */}
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.storeTransactionFee'
              values={{ value: '1.5%' }}
            />
          </ComparePlansModalFeature>
        )
        features.push(
          <ComparePlansModalFeature
            borderLeft={this.props.borderLeft}
            isMobile={this.props.isMobile}
            className='justify-content-center'
            borderBottom
          >
            <Text
              message='modules.upgradeModels.comparePlansModalPlan.shippingManagement'
              values={{ value: <Text message='modules.upgradeModels.comparePlansModalPlan.advanced1' /> }}
            />
          </ComparePlansModalFeature>
        )
      }
    }

    return features
  }

  renderPlanPromotions () {
    const isCreativEmail = getRuntimeConfig()._brand === 'creativemail'

    if (isCreativEmail && this.props.plan.sku === 'PLAN2') {
      return this.renderFreePlanPromotion()
    }

    const plan = isCreativEmail ? getCreativEmailPlanPromotion(this.props.plan.sku) : this.props.plan

    const pricing = get(plan, 'pricing', [])
    const oneYearPlan = find(pricing, { term: 12 })
    const yearlyPrice = get(oneYearPlan, 'price', 0) / 100
    const monthlyPrice = get(oneYearPlan, 'monthlyPrice', 0) / 100
    const sku = plan.metadata && plan.metadata.sku && plan.metadata.sku.toLowerCase()
    const dataElementPrefix = `compare_plans_modal-type_${plan.type}-${sku}`
    const isWebsitebuilder = getRuntimeConfig()._brand === 'websitebuilder'
    const isCTCT = getRuntimeConfig()._brand === 'constantcontact'
    const isPopular = isCTCT && plan.sku === 'PLAN1'

    return (
      <Fragment>
        {isPopular
          ? isCTCT && (
            <PopularPlan className='p-2' borderBottom={this.props.isMobile}>
              <Text message='modules.upgradeModels.comparePlansModalPlan.mostPopular' />
            </PopularPlan>
            )
          : isCTCT && <PopularPlanPlaceHolder className='p-2'> &nbsp; </PopularPlanPlaceHolder>}
        <PromotePlanWrapper
          borderLeft={this.props.borderLeft}
          className={`d-flex align-items-center justify-content-center ${this.props.isMobile ? 'mobile' : ''}`}
          first={get(plan, 'metadata.sku') === 'PLAN2'}
        >
          <div className='d-block'>
            <PromotePlan
              title={plan.title}
              yearlyPrice={yearlyPrice}
              monthlyPrice={monthlyPrice}
              discountPercentage={get(plan, 'discountPercentage')}
              vatPercentage={this.props.vatPercentage}
              spaceBetweenDiscount={isWebsitebuilder}
            />
            <div className='d-flex justify-content-center' style={{ textAlign: 'center' }}>
              <Button
                className='mt-3'
                onClick={this.onClick}
                style={{ height: '100%', display: 'inherit' }}
                dataElementId={`${dataElementPrefix}-${kebabCase(plan.title)}-upgrade-cta`}
                dataElementLabel={dataElementPrefix}
                dataElementLocation={DataElementLocations.POP_UP}
              >
                <Text message='modules.upgradeModels.comparePlansModalPlan.buy2' />
                &nbsp;
                {replace(plan.title, 'Plan', '')}
              </Button>
            </div>
          </div>
        </PromotePlanWrapper>
      </Fragment>
    )
  }

  onClose = () => {
    this.setState((prevState) => ({ openComingSoon: !prevState.openComingSoon }))
  }

  renderFreePlanPromotion () {
    const { borderLeft, isMobile, vatPercentage } = this.props

    return (
      <PromotePlanWrapper
        borderLeft={borderLeft}
        className={`d-flex flex-column align-items-center justify-content-center ${isMobile ? 'mobile' : ''}`}
        first
      >
        <div className='d-flex justify-content-center'>
          <Typography variant='h3'>Free Plan</Typography>
        </div>
        {!isMobile && (
          <>
            {vatPercentage > 0 && <div id='vat-filler' className='d-block' style={{ height: '56px' }} />}
            <div id='filler' className='d-block' style={{ height: '120px' }} />
          </>
        )}
      </PromotePlanWrapper>
    )
  }

  onClick = () => {
    if (getRuntimeConfig()._brand === 'creativemail' && getRuntimeConfig()._env !== 'dev') {
      this.setState({ openComingSoon: true })
      return
    }
    const price = get(this.props.plan, 'price', 0) / 100
    this.props.onClick(this.props.plan.sku, this.props.plan.title, price)
  }

  render () {
    return (
      <div className={this.props.isMobile ? 'col-12' : 'col'}>
        {this.renderPlanPromotions()}
        {this.props.isMobile ? (
          <ExpansionPanelWrapper>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Text message='modules.upgradeModels.comparePlansModalPlan.features' />
            </ExpansionPanelSummary>
            <ExpansionPanelDetailsStyle>{this.renderFeatures()}</ExpansionPanelDetailsStyle>
          </ExpansionPanelWrapper>
        ) : (
          this.renderFeatures()
        )}
        <CompositionsModal
          open={this.state.openComingSoon}
          withContentBorder
          maxWidth='sm'
          onClose={this.onClose}
          title={<Text message='modules.upgradeModels.comparePlansModalPlan.comingSoon.title' />}
          actions={[
            <Button
              key='close'
              dataElementLocation={DataElementLocations.POP_UP}
              dataElementLabel='upgrade-coming-soon-close'
              dataElementId='upgrade-coming-soon-close'
              color='primary'
              variant='outlined'
              className='mr-2'
              onClick={this.onClose}
            >
              <Text message='modules.upgradeModels.comparePlansModalPlan.comingSoon.close' />
            </Button>
          ]}
        >
          <Text message='modules.upgradeModels.comparePlansModalPlan.comingSoon.description' />
        </CompositionsModal>
      </div>
    )
  }
}

export default ComparePlansModalPlanComponent
