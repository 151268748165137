import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import Typography from '@eig-builder/core-ui/Typography'
import './styles/index.scss'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import map from 'lodash/map'
import first from 'lodash/first'
import sortBy from 'lodash/sortBy'
import union from 'lodash/union'
import each from 'lodash/each'
import find from 'lodash/find'
import concat from 'lodash/concat'

import BootstrapperContext from '@eig-builder/module-bootstrapper/contexts/BootstrapperContext'

import { ResponsiveModalWithBottomNavigationBar } from '@eig-builder/compositions-responsive-modal'
import BottomNavigationBarWithProgress from '@eig-builder/compositions-bottom-navigation-bar-with-progress'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import Button from '@eig-builder/core-ui/Button'
import Text from '@eig-builder/module-localization'

import { SERVER } from 'core/sdk-communication/enums/application-types'
import { CLOSE_MODAL_IFRAME } from 'core/sdk-communication/enums/message-types'
import postMessageSender from 'core/sdk-communication/post-message-sender'

import ComparePlansModalDisclaimer from './../compare-plans-modal-disclaimer'

import { getPublicUrl } from '@eig-builder/core-utils/helpers/url-helper'
import { getDataProperty, DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Plan from './../compare-plans-modal-plan'

import { setCartItems, setLimitationKey, setPurchaseOrigin, resetCouponCode } from 'core/cart/utility'

import './lang'

import ComparePlansModalFeature from './../compare-plans-modal-feature'
import ComparePlansSkeleton from './../compare-plans-skeleton'
import MD from '@eig-builder/core-utils/helpers/mobile-detection-helper'
import HintTooltip from '@eig-builder/compositions-hint-tooltip'
import { Alert } from '@material-ui/lab'
import { Box } from '@material-ui/core'

const parseFeatures = feature => {
  return feature
    .trim()
    .split(',')
    .filter(i => i)
    .map(item => item.trim())
}

const creativEmailFeatureNames = [
  'Total Contacts',
  'Custom Audiences',
  'Premium Sections',
  'Campaigns per Month',
  'Unique Emails per Month',
  'CSV Upload',
  'Footer Ads'
]

class ComparePlansModalComponent extends PureComponent {
  static contextType = BootstrapperContext

  static propTypes = {
    getPlans: PropTypes.func.isRequired,
    extraInfo: PropTypes.object,
    getPlansResponse: PropTypes.object, //eslint-disable-line
    upgradeSkus: PropTypes.any, //eslint-disable-line
    discountDefinitions: PropTypes.array,
    currencyCode: PropTypes.string,
    vatPercentage: PropTypes.number,
    type: PropTypes.string,
    isLoadingModal: PropTypes.bool
  }

  static getDerivedStateFromProps (props, state) {
    const plans = sortBy(
      filter(
        get(first(props.getPlansResponse), 'products'),
        item =>
          props.upgradeSkus.indexOf(item.sku) !== -1)
      , item => item.price)

    let allFeatures = []
    each(plans, plan => {
      // map features
      const match = (get(plan, 'metadata.features') || '').match(/included:(.*)not_included:(.*)|included:(.*)/i)
      if (match) {
        plan.features = parseFeatures(match[1] || match[3])
        allFeatures = concat(allFeatures, plan.features)
        plan.missingFeatures = parseFeatures(match[2])
      }

      // add discount
      plan.discountPercentage = get(find(props.discountDefinitions, ad => ad.sku === plan.sku && (ad.term === props.promotedTerm || ad.term === null)), 'value', 0)
    })
    return {
      plans: plans,
      features: union(allFeatures)
    }
  }

  constructor () {
    super()
    this.state = {}

    this.renderFeatures = this.renderFeatures.bind(this)
  }

  componentDidMount () {
    this.props.getPlans()
  }

  onPlanClick = (sku, name, price) => {
    const isWebsitebuilder = getRuntimeConfig()._brand === 'websitebuilder'
    const itemsToAddToCart = []
    itemsToAddToCart.push({
      sku: sku,
      selected_term: 12,
      extra_info: {
        site_id: this.props.extraInfo.sideId,
        frontend_price: price,
        frontend_display_name: name
      }
    })

        // 60% DISCOUNT DOESN'T Apply to plan 9
    if (sku === 'PLAN9' && isWebsitebuilder) {
      resetCouponCode()
    }

    setLimitationKey('site')
    setPurchaseOrigin(this.props.extraInfo.purchaseOrigin)
    setCartItems(this.context.analyticsService, itemsToAddToCart)
    window.location.href = '/onboarding/modal'
  }

  onClose = () => {
    postMessageSender.createAndSendMessage(SERVER, CLOSE_MODAL_IFRAME)
  }

  renderFeatures () {
    const isWebsitebuilder = getRuntimeConfig()._brand === 'websitebuilder'
    const isCreativEmail = getRuntimeConfig()._brand === 'creativemail'
    const isCTCT = getRuntimeConfig()._brand === 'constantcontact'

    const featureNames = isCreativEmail
      ? creativEmailFeatureNames
      : this.state.features

    const features = map(featureNames, (feature, i, arr) => <ComparePlansModalFeature
      key={i}
      borderLeft
      borderBottom={((arr.length - 1) === i) && !isWebsitebuilder && !isCTCT}
      className='px-2'
      >
      <Typography variant='h4'>{feature}</Typography>
    </ComparePlansModalFeature>)

    if (isWebsitebuilder || isCTCT) {
      features.push(<ComparePlansModalFeature className='px-2' borderLeft>
        <Typography variant='h4'><Text message='modules.upgradeModels.comparePlansModal.storeProducts' /></Typography>
      </ComparePlansModalFeature>)
      features.push(<ComparePlansModalFeature className='px-2' borderLeft>
        <Typography variant='h4'><Text message='modules.upgradeModels.comparePlansModal.storeTransactionFee' /></Typography>
      </ComparePlansModalFeature>)
      features.push(<ComparePlansModalFeature className='px-2' borderLeft borderBottom>
        <Typography variant='h4'><Text message='modules.upgradeModels.comparePlansModal.shippingManagement' /></Typography>
      </ComparePlansModalFeature>)
    }

    return features
  }

  render () {
    const { plans } = this.state
    const { type } = this.props

    const hasPromotion = !!this.props.discountDefinitions
    const isGator = getRuntimeConfig()._brand === 'gator'
    const dataElementPrefix = `compare_plans_modal-type_${type}`

    const isLoading = this.props.isLoadingModal || isEmpty(plans)
    const isFreeToPaid = this.props.extraInfo?.freeToPaid ?? false
    const nextSite = this.props.extraInfo?.nextSite ?? null

    return (<ResponsiveModalWithBottomNavigationBar
      maxWidth='lg'
      scroll='body'
      onClose={() => postMessageSender.createAndSendMessage(SERVER, CLOSE_MODAL_IFRAME)}
      bottomNavigationBarWithProgress={
        <BottomNavigationBarWithProgress
          next={<Button
            color='primary'
            onClic={() => {
              try {
                const siteId = this.props.extraInfo.nextSiteId
                window && window.localStorage.setItem('CURRENT_SITE', siteId)
              } catch {
                // Could not write to localstorage
              }
            }}
          >Go to Site</Button>}
          previous={<Button
            dataElementId={`${dataElementPrefix}-cancel_cta`}
            dataElementLabel={dataElementPrefix}
            dataElementLocation={DataElementLocations.POP_UP}
            variant='text'
            color='primary'
            style={{ height: '100%' }}
            onClick={this.onClose}
          >
            <Text message='modules.upgradeModels.comparePlansModal.previous' />
          </Button>}
        />
      }
      >

      <div className='p-4'>
        {isLoading ? <ComparePlansSkeleton />
          : <>
            <ModalHeader isFreeToPaid={isFreeToPaid} nextSite={nextSite} />
            {!nextSite && <ModalContent
              renderFeatures={this.renderFeatures}
              plans={plans}
              features={this.state.features}
              onPlanClick={this.onPlanClick}
              vatPercentage={this.props.vatPercentage}
              hasPromotion={hasPromotion}
              currencyCode={this.props.currencyCode}
              isGator={isGator}
              dataElementPrefix={dataElementPrefix}
              />}
          </>}
      </div>
    </ResponsiveModalWithBottomNavigationBar>)
  }
}

const ModalHeader = ({ isFreeToPaid, nextSite }) => {
  const shutdownDate = "'undefined date'"

  return (isFreeToPaid
    ? <div className='pb-3'>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        {nextSite &&
          <Box style={{ flexBasis: 'auto' }} className='mb-4'>
            <Alert severity='warning'>
              <Typography variant='body1' className='text-center'>
                <Text message='modules.upgradeModels.comparePlansModal.freeToPaidWarning' values={[shutdownDate]} />
              </Typography>
            </Alert>
          </Box>}
        <Box style={{ flexBasis: 'content' }}>
          <Typography variant='h1' className='text-center pb-1'>
            <Text message='modules.upgradeModels.comparePlansModal.freeToPaidTitle' />
            <HintTooltip placement='bottom-end'>
              <Text message='modules.upgradeModels.comparePlansModal.freeToPaidToolHip' values={[shutdownDate]} />
            </HintTooltip>
          </Typography>
          <Typography variant='body1' className='text-center'>
            <Text message='modules.upgradeModels.comparePlansModal.freeToPaidMessage3' values={[shutdownDate]} />
          </Typography>
        </Box>
      </Box>
    </div>
    : null)
}

ModalHeader.propTypes = {
  isFreeToPaid: PropTypes.bool,
  nextSite: PropTypes.number
}

const ModalContent = ({ renderFeatures, plans, features, onPlanClick, vatPercentage, hasPromotion, currencyCode, isGator, dataElementPrefix }) => {
  return <Fragment>
    <div key='1' className='row no-gutters'>
      {
        plans.map((plan, i) => (<Plan
          key={i}
          borderLeft={MD() ? true : i === 0}
          allFeatures={features}
          plan={plan}
          onClick={onPlanClick}
          vatPercentage={vatPercentage}
        />))
      }
    </div>

    <div className='d-flex flex-row justify-content-center pt-3'>
      <ComparePlansModalDisclaimer
        hasPromotion={hasPromotion}
        currencyCode={currencyCode}
      />
    </div>
    <div className='d-flex flex-row justify-content-center py-3'>
      <Typography variant='body2'>
        <Text message='modules.upgradeModels.comparePlansModal.learnMoreAbout' />&nbsp;
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={isGator ? getPublicUrl() + '/website-builder' : getPublicUrl() + '/pricing'}
          {...getDataProperty({
            dataElementId: `${dataElementPrefix}-learn_more_about_plan_details_and_pricing`,
            dataElementLabel: dataElementPrefix,
            dataElementLocation: DataElementLocations.POP_UP
          })}
        >
          <Typography
            variant='link2'
            className='d-inline'
          >
            {isGator ? getPublicUrl() + '/website-builder' : getPublicUrl() + '/pricing'}
          </Typography>
        </a>
      </Typography>
    </div>
  </Fragment>
}

ModalContent.propTypes = {
  renderFeatures: PropTypes.func,
  plans: PropTypes.array,
  features: PropTypes.array,
  onPlanClick: PropTypes.func,
  vatPercentage: PropTypes.any,
  hasPromotion: PropTypes.bool,
  currencyCode: PropTypes.any,
  isGator: PropTypes.bool,
  dataElementPrefix: PropTypes.string
}

export default ComparePlansModalComponent
