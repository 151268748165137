import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import Typography from '@eig-builder/core-ui/Typography'
import './styles/index.scss'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import first from 'lodash/first'
import sortBy from 'lodash/sortBy'
import union from 'lodash/union'
import each from 'lodash/each'
import find from 'lodash/find'
import concat from 'lodash/concat'

import BootstrapperContext from '@eig-builder/module-bootstrapper/contexts/BootstrapperContext'

import { ResponsiveModalWithBottomNavigationBar } from '@eig-builder/compositions-responsive-modal'
import BottomNavigationBarWithProgress from '@eig-builder/compositions-bottom-navigation-bar-with-progress'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import Button from '@eig-builder/core-ui/Button'
import Text from '@eig-builder/module-localization'

import { SERVER } from 'core/sdk-communication/enums/application-types'
import { CLOSE_MODAL_IFRAME } from 'core/sdk-communication/enums/message-types'
import postMessageSender from 'core/sdk-communication/post-message-sender'

import ComparePlansModalDisclaimer from './../compare-plans-modal-disclaimer'

import { getPublicUrl } from '@eig-builder/core-utils/helpers/url-helper'
import { getDataProperty, DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Plan from './../compare-plans-modal-plan'

import { setCartItems, setLimitationKey, setPurchaseOrigin } from 'core/cart/utility'

import './lang'
import { Loader } from '@eig-builder/compositions-loader-with-timeout'

import MD from '@eig-builder/core-utils/helpers/mobile-detection-helper'

import CreativeMailUpgradeModal from './components/creativemail-upgrade-modal'

const parseFeatures = (feature) => {
  return feature
    .trim()
    .split(',')
    .filter((i) => i)
    .map((item) => item.trim())
}

class ComparePlansModalComponent extends PureComponent {
  static contextType = BootstrapperContext

  static propTypes = {
    billingInfo: PropTypes.object,
    getPlans: PropTypes.func.isRequired,
    extraInfo: PropTypes.object,
    getPlansResponse: PropTypes.object, //eslint-disable-line
    upgradeSkus: PropTypes.any, //eslint-disable-line
    discountDefinitions: PropTypes.array,
    currencyCode: PropTypes.string,
    vatPercentage: PropTypes.number,
    type: PropTypes.string
  }

  static getDerivedStateFromProps (props, state) {
    const plans = sortBy(
      filter(get(first(props.getPlansResponse), 'products'), (item) => props.upgradeSkus.indexOf(item.sku) !== -1),
      (item) => item.sku
    )

    let allFeatures = []
    each(plans, (plan) => {
      // map features
      const match = (get(plan, 'metadata.features') || '').match(/included:(.*)not_included:(.*)|included:(.*)/i)
      if (match) {
        plan.features = parseFeatures(match[1] || match[3])
        allFeatures = concat(allFeatures, plan.features)
        plan.missingFeatures = parseFeatures(match[2])
      }

      // add discount
      plan.discountPercentage = get(
        find(
          props.discountDefinitions,
          (ad) => ad.sku === plan.sku && (ad.term === props.promotedTerm || ad.term === null)
        ),
        'value',
        0
      )
    })
    return {
      plans: plans,
      features: union(allFeatures)
    }
  }

  constructor () {
    super()
    this.state = {}
  }

  componentDidMount () {
    this.props.getPlans()
  }

  onPlanClick = (sku, name, price) => {
    const isCreativeMail = getRuntimeConfig()._brand === 'creativemail'

    let selectedTerm = 12
    if (isCreativeMail) {
      // We only have monthly plans for CreativeMail
      selectedTerm = 1

      // prevent plan2 from showing a plan1's discount code
      // EWE-5099 Remove default discount for creativemail
      // if (sku === 'PLAN2_CE') {
      //   resetCouponCode(true)
      // } else if (sku === 'PLAN1_CE') {
      //   setCouponCode(DEFAULT_CREATIVEMAIL_COUPON)
      // }
    }

    const itemsToAddToCart = []
    itemsToAddToCart.push({
      sku: sku,
      selected_term: selectedTerm,
      extra_info: {
        site_id: this.props.extraInfo.sideId,
        frontend_price: price,
        frontend_display_name: name
      }
    })
    setLimitationKey('site')
    setPurchaseOrigin(this.props.extraInfo.purchaseOrigin)
    setCartItems(this.context.analyticsService, itemsToAddToCart)

    window.location.href = '/onboarding/modal'
  }

  onClose = () => {
    postMessageSender.createAndSendMessage(SERVER, CLOSE_MODAL_IFRAME)
  }

  getCurrentPlan = (plans) => {
    switch (plans.length) {
      case 0:
        return 'PLAN2_CE'
      case 1:
        return 'PLAN1_CE'
      case 2:
        return 'PLAN'
      default:
        return 'PLAN'
    }
  }

  render () {
    const { plans } = this.state
    const { type, getPlansResponse, billingInfo } = this.props

    const hasPromotion = !!this.props.discountDefinitions
    const isGator = getRuntimeConfig()._brand === 'gator'
    const isCreativeMail = getRuntimeConfig()._brand === 'creativemail'

    const dataElementPrefix = `compare_plans_modal-type_${type}`
    const organizedPlans = plans.sort((a, b) => a.price - b.price)

    if (isCreativeMail) {
      return (
        <CreativeMailUpgradeModal
          open
          plans={(getPlansResponse && getPlansResponse.length > 0) ? getPlansResponse[0].products : []}
          currentPlan={getPlansResponse && getPlansResponse.length > 0 && this.getCurrentPlan(organizedPlans)}
          onClickCheckout={this.onPlanClick}
          onClose={this.onClose}
          currencySymbol={billingInfo?.symbol}
        />
      )
    }

    return (
      <ResponsiveModalWithBottomNavigationBar
        maxWidth='lg'
        scroll={MD() ? '' : 'body'}
        onClose={() => postMessageSender.createAndSendMessage(SERVER, CLOSE_MODAL_IFRAME)}
        bottomNavigationBarWithProgress={
          <BottomNavigationBarWithProgress
            next={null}
            previous={
              <Button
                dataElementId={`${dataElementPrefix}-cancel_cta`}
                dataElementLabel={dataElementPrefix}
                dataElementLocation={DataElementLocations.POP_UP}
                variant='text'
                color='primary'
                style={{ height: '100%' }}
                onClick={this.onClose}
              >
                <Text message='modules.upgradeModels.comparePlansModal.previous' />
              </Button>
            }
          />
        }
      >
        <div className='p-4'>
          {isEmpty(organizedPlans) ? (
            <Loader timeOutMs={1500} centeredX centeredY withPaddingY={false} />
          ) : (
            <Fragment>
              <div key='1' className='row no-gutters'>
                {MD() ? (
                  <>
                    {organizedPlans.sort((a, b) => a.price - b.price).map((plan, i) => (
                      <Plan
                        key={i}
                        borderLeft={i === 0}
                        allFeatures={this.state.features}
                        plan={plan}
                        onClick={this.onPlanClick}
                        vatPercentage={this.props.vatPercentage}
                        isMobile
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {organizedPlans.map((plan, i) => (
                      <Plan
                        key={i}
                        borderLeft={i === 0}
                        allFeatures={this.state.features}
                        plan={plan}
                        onClick={this.onPlanClick}
                        vatPercentage={this.props.vatPercentage}
                      />
                    ))}
                  </>
                )}
              </div>

              <div className='d-flex flex-row justify-content-center pt-3'>
                <ComparePlansModalDisclaimer hasPromotion={hasPromotion} currencyCode={this.props.currencyCode} />
              </div>
              <div className='d-flex flex-row justify-content-center py-3'>
                <Typography variant='body2'>
                  <Text message='modules.upgradeModels.comparePlansModal.learnMoreAbout' />
                  &nbsp;
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={isGator ? getPublicUrl() + '/website-builder' : getPublicUrl() + '/pricing'}
                    {...getDataProperty({
                      dataElementId: `${dataElementPrefix}-learn_more_about_plan_details_and_pricing`,
                      dataElementLabel: dataElementPrefix,
                      dataElementLocation: DataElementLocations.POP_UP
                    })}
                  >
                    <Typography variant='link2' className='d-inline'>
                      {isGator ? getPublicUrl() + '/website-builder' : getPublicUrl() + '/pricing'}
                    </Typography>
                  </a>
                </Typography>
              </div>
            </Fragment>
          )}
        </div>
      </ResponsiveModalWithBottomNavigationBar>
    )
  }
}

export default ComparePlansModalComponent
