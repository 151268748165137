import React, { memo, Fragment } from 'react'
import PropTypes from 'prop-types'
import Text from '@eig-builder/module-localization'
import UpgradeModalDisclaimer from '../upgrade-modal-disclaimer'
import { getPublicUrl } from '@eig-builder/core-utils/helpers/url-helper'
import Typography from '@eig-builder/core-ui/Typography'
import UpgradeModalDomainPrice from './../upgrade-modal-cancelation-domain-price'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'

import styled from 'styled-components'

import './lang'

const MaxWidth = styled.div`
  max-width: 820px;
  text-align: center;
`

const ComparePlansModalDisclaimer = (props) => {
  const isGator = getRuntimeConfig()._brand === 'gator'
  const disclaimer = []
  if (props.hasPromotion) {
    disclaimer.push(<Fragment><Text message='modules.upgradeModels.comparePlansModalDisclaimer.promotion' />&nbsp;<a
      target='_blank'
      rel='noopener noreferrer'
      href={isGator ? getPublicUrl() + '/website-builder' : getPublicUrl() + '/pricing'}
      >
      <Typography variant='link2' className='d-inline'>
        <Text message='modules.upgradeModels.comparePlansModalDisclaimer.regularRates' />
      </Typography>
    </a>&nbsp;
    </Fragment>)
  }

  disclaimer.push(<Text
    message='modules.upgradeModels.comparePlansModalDisclaimer.title'
    values={{
      price: <UpgradeModalDomainPrice currencyCode={props.currencyCode} />
    }}
                  />)

  return (<UpgradeModalDisclaimer
    title={<MaxWidth>&#42;{disclaimer}</MaxWidth>}
          />)
}

ComparePlansModalDisclaimer.propTypes = {
  currencyCode: PropTypes.string,
  hasPromotion: PropTypes.bool
}

export default memo(ComparePlansModalDisclaimer)
