import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  modules: {
    upgradeModels: {
      comparePlansModal: {
        next: 'Continue to Payment',
        previous: 'Cancel',
        learnMoreAbout: 'Learn more about plan details and pricing on ',
        storeProducts: 'Store Products',
        storeTransactionFee: 'Store transaction fee',
        shippingManagement: 'Shipping management',
        freeToPaidTitle: 'YOUR PLAN IS CHANGING – ACTION REQUIRED​',
        freeToPaidMessage3: 'To prevent any suspension in services, please choose a plan below and enter your billing information by {0}.',
        freeToPaidWarning: 'You still have unpaid published websites! Please select the site and choose a plan.',
        freeToPaidToolHip: `We appreciate your trust in allowing us to assist you with your professional website, hosting and support needs. We are committed to providing our customers some of the most secure websites in the industry. With the rising costs associated with website security and infrastructure, we wanted to inform you of a modest price increase to your existing plan. To prevent any suspension in services, including the unpublishing of your current website, please choose a plan below and enter your billing information by <x date>.`
      }
    }
  }
})
